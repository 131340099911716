import React from "react";
import { Container, Row } from "react-bootstrap";


function MentionsLegales() {
  return (
    <Container className="my-5 text-center">
      <Row>
        <h1 className="mt-5">Mentions Légales</h1>
        <h2 className="mt-5">1. Éditeur du site</h2>
        <p>
          Nom de l’auto-entreprise : mskConseil <br />
          Statut juridique : Auto-entrepreneur <br />
          Nom du responsable de la publication : SKALSKI Marc <br />
          Adresse : 5039b Allé du Château 30520 Saint-Martin-de-Valgualgues{" "}
          <br />
          Téléphone : 06 58 09 36 85 <br />
          Email : mskconseils30@gmail.com <br />
          Numéro SIRET : 51354598800034 <br />
          TVA non applicable, art. 293 B du CGI. <br />
          Code APE : 7022Z
        </p>

        <h2 className="mt-5">2. Hébergeur du site</h2>
        <p>
          Nom de l’hébergeur : 02switch <br />
          Adresse de l’hébergeur : Chemin des Pardiaux 63000 Clermont-Ferrand{" "}
          <br />
          Téléphone de l’hébergeur : 04 44 44 60 40
        </p>

        <h2 className="mt-5">3. Propriété intellectuelle</h2>
        <p>
          Tous les contenus présents sur ce site web, y compris, mais sans s'y
          limiter, les textes, images, graphiques, logos, icônes, sons et
          logiciels, sont la propriété de mskconseil ou de ses fournisseurs de
          contenu, et sont protégés par les lois internationales sur les droits
          d'auteur. L'organisation et la présentation de tout ce contenu, ainsi
          que le code source, sont la propriété exclusive de mskconseil. Toutes
          les marques commerciales utilisées ou mentionnées sur ce site sont la
          propriété de leurs propriétaires respectifs. L'utilisateur n'est pas
          autorisé à utiliser, reproduire, copier, modifier, transmettre,
          diffuser, vendre, licencier, ou de toute autre manière exploiter le
          contenu du site à des fins commerciales, sans l'autorisation écrite
          préalable de mskConseil ou du titulaire des droits. Toute violation de
          ces droits peut entraîner des poursuites civiles ou pénales
          conformément aux lois applicables. Ce site peut contenir des
          informations fournies par des entreprises externes ou des liens
          hypertextes vers d'autres sites qui ne sont pas gérés par mskconseil.
          Ces liens et informations sont fournis à titre de convenance pour
          l'utilisateur. L'existence d'un lien vers un autre site ne constitue
          pas une approbation de ce site ou de son contenu par mskConseil. Il
          incombe à l'utilisateur d'utiliser ces informations avec discernement
          et un esprit critique. La responsabilité de mskConseil ne peut être
          engagée en raison des informations, opinions et recommandations
          fournies par des tiers. Les entreprises qui souhaitent établir un lien
          vers ce site sont libres de le faire. Nous apprécierions que vous nous
          informiez de la mise en place d'un lien en nous contactant via le
          formulaire de contact, en nous fournissant votre raison sociale, votre
          activité et l'URL de la page où se trouve le lien.
        </p>

        <h2 className="mt-5">4. Protection des données personnelles</h2>
        <p>
          En conformité avec la loi « Informatique et Libertés » du 6 janvier
          1978 modifiée et le Règlement Général sur la Protection des Données
          (RGPD), tu as un droit d’accès, de rectification, d’opposition, de
          suppression et de portabilité des données personnelles te concernant.
          Tu peux exercer ces droits en nous contactant à l'adresse suivante :
          mskconseils30@gmail.com.
        </p>

        <h2 className="mt-5">5. Cookies</h2>
        <p>
          Le site solutionweb utilise des cookies pour améliorer l’expérience
          utilisateur. Vous pouvez choisir de désactiver les cookies dans les
          paramètres de ton navigateur.
        </p>

        <h2 className="mt-5">6. Responsabilité</h2>
        <p>
          L’éditeur ne saurait être tenu responsable des dommages directs ou
          indirects, y compris, mais sans s’y limiter, les pertes de profits, de
          clientèle ou de données résultant de l’utilisation du site ou de
          l’impossibilité d’accès.
        </p>

        <h2 className="mt-5">7. Conditions Générales de Vente (CGV)</h2>
        <p>
          Le présent contrat a pour objet la vente de services de création de
          sites internet. Les prix sont indiqués en euros, hors taxes, et sont
          précisés sur la page des tarifs. Le paiement doit être effectué comme
          indiqué dans le contrat. Le client dispose d'un droit de rétractation
          de 14 jours après la commande, sauf en cas de personnalisation du
          service. En cas de litige, les parties s’engagent à rechercher une
          solution à l’amiable, à défaut le tribunal de Alès sera compétent.
        </p>

        <h2 className="mt-5">8. Politique de Confidentialité</h2>
        <p>
          Le site solutionweb collecte certaines données personnelles via les
          formulaires de contact afin de répondre aux demandes des utilisateurs.
          Les données collectées incluent le nom, l'adresse email, et tout autre
          renseignement fourni volontairement par l'utilisateur. Ces données ne
          sont pas partagées avec des tiers et sont conservées pendant une
          période de 1 mois. Conformément au Règlement Général sur la Protection
          des Données (RGPD), les utilisateurs peuvent exercer leurs droits
          d’accès, de rectification, ou de suppression de leurs données en
          contactant mskconseils30@gmail.com.
        </p>

        <h2 className="mt-5">9. Médiateur de la consommation</h2>
        <p>
          En cas de litige concernant les services proposés, le client a la
          possibilité de recourir à un médiateur de la consommation en
          contactant [Nom du médiateur], à l’adresse suivante : [Adresse du
          médiateur] ou via leur site internet : [Lien vers le site du
          médiateur].
        </p>

        <h2 className="mt-5">10. Politique de Cookies</h2>
        <p>
          Le site solutionweb utilise des cookies pour améliorer l'expérience
          utilisateur et réaliser des statistiques de visites. Vous pouvez
          choisir d'accepter ou de refuser les cookies en ajustant les
          paramètres de votre navigateur. En continuant à naviguer sur notre
          site sans modifier vos paramètres, vous acceptez l'utilisation des
          cookies.
        </p>

        <h2 className="mt-5">11. Service après-vente et maintenance</h2>
        <p>
          Un service de support technique est disponible pour corriger les
          éventuels bugs et dysfonctionnements du site pendant la période
          indiqué sur le contrat, à compter de la livraison. Le support est
          joignable par email à mskconseils30@gmail.com du lundi au vendredi, de
          9h à 19h.
        </p>
      </Row>
    </Container>
  );
}

export default MentionsLegales;
