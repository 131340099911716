import React, { useState, useEffect } from "react";
import { db } from "./Firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Container, Row, Col, Form, Alert, Button } from "react-bootstrap";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import ArticlesManager from "./ArticlesManager";

function ContentManager() {
  // States pour la gestion du contenu existant
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    const fetchContent = async () => {
      try {
        const docRef = doc(db, "contenu", "IMxamWzEbadufG7RtzuQ");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setTitle(data.titre);
          setDescription(data.description);
        } else {
          console.log("Aucun document trouvé !");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du contenu :", error);
      }
    };

    fetchContent();

    // Cleanup: déconnecte l'utilisateur lorsqu'il quitte la page
    return () => {
      signOut(auth)
        .then(() => {
          console.log("Déconnexion automatique réussie !");
        })
        .catch((error) => {
          console.error("Erreur lors de la déconnexion automatique :", error);
        });
      unsubscribe();
    };
  }, [auth, navigate]);

  // Fonction pour sauvegarder le contenu existant
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const docRef = doc(db, "contenu", "IMxamWzEbadufG7RtzuQ");
      await setDoc(docRef, {
        titre: title,
        description: description,
      });

      setMessage("Contenu mis à jour avec succès !");
      setError(null);
    } catch (error) {
      setError("Erreur lors de la mise à jour du contenu.");
      setMessage(null);
    }
    setLoading(false);
  };

  // Fonction de déconnexion manuelle
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("Déconnexion réussie !");
        navigate("/");
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion :", error);
      });
  };

  return (
    <Container fluid className="my-5">
      <Row>
        <h1 className="text-center mt-5">Gestion du contenu</h1>

        <Col md={6} className="mt-5">
          <Button
            onClick={handleLogout}
            className="mb-5"
            variant="danger"
            aria-label="Déconnexion"
          >
            Déconnexion
          </Button>
          <h2>Page Galerie</h2>

          {/* Formulaire pour modifier le contenu existant */}
          <Form onSubmit={handleSave}>
            <Form.Group controlId="formTitle">
              <Form.Label className="fw-bold">Titre</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                aria-label="Modifier le titre de la page galerie"
              />
            </Form.Group>
            <Form.Group controlId="formDescription" className="mb-3">
              <Form.Label className="fw-bold">Description</Form.Label>
              <Form.Control
                as="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                aria-label="Modifier la description de la page galerie"
              />
            </Form.Group>
            {error && <Alert variant="danger" style={{ width: "max-content"}}>{error}</Alert>}
            {message && <Alert variant="success" style={{ width: "max-content"}}>{message}</Alert>}
            <Button
              type="submit"
              className="mt-3"
              variant="primary"
              disabled={loading}
              aria-label="Sauvegarder les modifications"
            >
              {loading ? "Sauvegarde..." : "Sauvegarder"}
            </Button>
          </Form>
        </Col>
      </Row>
      <Row>
        
        <ArticlesManager />
        
        <Col md={6} className="mt-5">
  <h3>Comment intégrer des fichiers optimisés</h3>
  <p>
    Pour optimiser le poids de votre site et améliorer son référencement, il est recommandé d'utiliser des fichiers images et médias bien optimisés.
  </p>
  <ul>
    <li className="mt-2">
      <strong>Format du fichier :</strong> Utilisez des formats modernes comme <code>.webp</code> ou <code>.avif</code> pour les images, et privilégiez les formats adaptés pour les vidéos comme <code>.mp4</code> ou <code>.webm</code>.
    </li>
    <li className="mt-2">
      <strong>Taille maximale :</strong> Essayez de ne pas dépasser <code>1 MB</code> par fichier. Compressez les fichiers avant de les téléverser.
    </li>
    <li className="mt-2">
      <strong>Résolution :</strong> Pour les images, une résolution de <code>72 DPI</code> est suffisante pour le web. Pour les vidéos, assurez-vous que la résolution est adaptée à la taille de l'affichage sans compromettre la qualité.
    </li>
    <li className="mt-2">
      <strong>Outils de compression :</strong> Vous pouvez utiliser des outils comme <a href="https://tinypng.com" style={{ color: "red" }} target="_blank" rel="noopener noreferrer"><code>TinyPNG</code></a>, <a href="https://imageoptim.com" style={{ color: "red" }} target="_blank" rel="noopener noreferrer"><code>ImageOptim</code></a> ou <a href="https://handbrake.fr" style={{ color: "red" }} target="_blank" rel="noopener noreferrer"><code>HandBrake</code></a> pour réduire la taille des fichiers sans perte visible de qualité.
    </li>
    <li className="mt-2">
      <strong>SEO :</strong> Les titres et les descriptions sont importants pour chaque image et vidéo afin d'améliorer le référencement naturel de votre site.
    </li>
  </ul>
</Col>

      </Row>
    </Container>
  );
}

export default ContentManager;
