import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/Footer.css";

function Footer() {
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <footer className="bg-dark text-light py-4 content">
      <Container fluid>
        <Row>
          <Col md={2} className="d-flex justify-content-center">
            <img src="/favicon.png" alt="Logo" width="120" height="120" />
          </Col>
          <Col md={7}>
            <div className="d-flex flex-column ms-3">
            <p>MARC SKALSKI DIGITAL FREELANCE</p>
              <a href="tel:+33658093685" className="text-light">
                Tél : 06 58 09 36 85
              </a>
              <a href="mailto:mskconseils30@gmail.com" className="text-light">
                mskconseils30@gmail.com
              </a>
            </div>
          </Col>
          <Col md={2}>
            <h5>Liens utiles</h5>
            <Nav.Link as={Link} to="/" className="mb-2 underline">
              Accueil
            </Nav.Link>

            {/* <NavDropdown title="Tarifs" id="tarifs-dropdown" className="mb-2" show={dropdownOpen} 
             onMouseEnter={() => setDropdownOpen(true)}  // Ouvrir au survol
             onMouseLeave={() => setDropdownOpen(false)} // Fermer lorsque la souris quitte
            >
              <NavDropdown.Item as={Link} to="/tarifs/creation-de-site">
                Création de site 
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tarifs/refonte-de-site">
                Refonte de site
              </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link as={Link} to="/galerie" className="mb-2 underline">
              Galerie
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" className="mb-2 underline">
              Contact
            </Nav.Link>
          </Col>
          <Col md={1}>
            <h5>Horaires</h5>
            <p>lundi-vendredi</p>
            <p>9h-19h</p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} Marc SKALSKI. Tous droits
              réservés.
            </p>
            <Nav.Link as={Link} to="/mentions-legales" className="me-4">
              Mention Légales
            </Nav.Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
