import React, { useState, useEffect, useRef } from "react";
import { db, storage } from "./Firebase";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { Col, Form, Button, Alert, Card, Row } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";

import "../styles/ArticlesManager.css";

function ArticlesManager() {
  const [articles, setArticles] = useState([]);
  const [formState, setFormState] = useState({
    title: "",
    description: "",
    media: null,
    id: null, // Identifiant de l'article à modifier
  });
  const [message, setMessage] = useState({ type: "", content: "" });
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const formRef = useRef(null);

  const MAX_FILE_SIZE = 20 * 1024 * 1024;

  useEffect(() => {
    fetchArticles();
  }, []);

  useEffect(() => {
    if (message.content) {
      const timer = setTimeout(
        () => setMessage({ type: "", content: "" }),
        3000
      );
      return () => clearTimeout(timer);
    }
  }, [message]);

  // Récupération des articles
  const fetchArticles = async () => {
    try {
      const articlesCollectionRef = collection(db, "articles");
      const articlesQuery = query(
        articlesCollectionRef,
        orderBy("createdAt", "asc")
      );
      const articlesSnapshot = await getDocs(articlesQuery);
      setArticles(
        articlesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    } catch {
      setMessage({
        type: "danger",
        content: "Erreur lors de la récupération des articles.",
      });
    }
  };

  const validateMedia = (media) => {
    if (!media) return true;
    if (media.size > MAX_FILE_SIZE) {
      setMessage({
        type: "danger",
        content: "Le fichier est trop volumineux. Maximum 20MB.",
      });
      return false;
    }
    if (!media.type.startsWith("image/") && !media.type.startsWith("video/")) {
      setMessage({
        type: "danger",
        content: "Le fichier doit être une image ou une vidéo.",
      });
      return false;
    }
    return true;
  };

  // Sauvegarde ou mise à jour d'un article
  const handleSaveOrUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { title, description, media, id } = formState;
    if (!validateMedia(media)) return setLoading(false);

    try {
      let mediaURL = "";
      let mediaType = "";
      const currentArticle = articles.find((a) => a.id === id);

      // Si un nouveau média est ajouté, supprimer l'ancien de Firebase Storage
      if (media) {
        if (currentArticle && currentArticle.media) {
          await deleteObject(ref(storage, currentArticle.media));
        }

        // Télécharger le nouveau média
        const storageRef = ref(storage, `media/${Date.now()}_${media.name}`);
        await uploadBytes(storageRef, media);
        mediaURL = await getDownloadURL(storageRef);
        mediaType = media.type; // Enregistrer le type MIME du fichier
      } else {
        // Garder l'URL et le type MIME du média existant si aucun nouveau média n'est fourni
        mediaURL = currentArticle ? currentArticle.media : "";
        mediaType = currentArticle ? currentArticle.mediaType : "";
      }

      if (id) {
        const articleRef = doc(db, "articles", id);
        await updateDoc(articleRef, {
          title,
          description,
          media: mediaURL,
          mediaType,
        });
        setMessage({
          type: "success",
          content: "Article modifié avec succès !",
        });
        setArticles((prev) =>
          prev.map((a) =>
            a.id === id
              ? { ...a, title, description, media: mediaURL, mediaType }
              : a
          )
        );
      } else {
        const newArticle = {
          title,
          description,
          media: mediaURL,
          mediaType,
          createdAt: new Date(),
        };
        const newArticleRef = await addDoc(
          collection(db, "articles"),
          newArticle
        );
        setArticles([...articles, { id: newArticleRef.id, ...newArticle }]);
        setMessage({
          type: "success",
          content: "Article ajouté avec succès !",
        });
      }
      resetForm();
    } catch (error) {
      setMessage({ type: "danger", content: `Erreur: ${error.message}` });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id, mediaURL) => {
    const confirmDelete = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet article ?"
    );
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, "articles", id));
      if (mediaURL) await deleteObject(ref(storage, mediaURL));
      setArticles(articles.filter((a) => a.id !== id));
      setMessage({
        type: "success",
        content: "Article supprimé avec succès !",
      });
    } catch (error) {
      setMessage({
        type: "danger",
        content: `Erreur lors de la suppression de l'article : ${error.message}`,
      });
    }
  };

  const resetForm = () => {
    setFormState({ title: "", description: "", media: null, id: null });
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Réinitialiser le champ fichier
    }
  };

  return (
    <Col md={6} className="mt-5">
      <h2 ref={formRef}>
        {formState.id ? "Modifier l'article" : "Ajouter un article"}
      </h2>
      <Form onSubmit={handleSaveOrUpdate}>
        <Form.Group controlId="articleTitle">
          <Form.Label className="fw-bold">Titre</Form.Label>
          <Form.Control
            type="text"
            value={formState.title}
            onChange={(e) =>
              setFormState({ ...formState, title: e.target.value })
            }
            required
          />
        </Form.Group>

        <Form.Group controlId="articleDescription">
          <Form.Label className="fw-bold">Description</Form.Label>
          <Form.Control
            as="textarea"
            value={formState.description}
            onChange={(e) =>
              setFormState({ ...formState, description: e.target.value })
            }
            required
          />
        </Form.Group>

        <Form.Group controlId="articleMedia" className="mb-3">
          <Form.Label className="fw-bold">Photo ou Vidéo</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) =>
              setFormState({ ...formState, media: e.target.files[0] })
            }
            ref={fileInputRef}
            required
          />
        </Form.Group>

        {message.content && (
          <Alert variant={message.type} style={{ width: "max-content" }}>
            {message.content}
          </Alert>
        )}

        <Button type="submit" variant="primary" disabled={loading}>
          {loading ? "Sauvegarde..." : formState.id ? "Modifier" : "Ajouter"}
        </Button>
      </Form>

      <Row className="mt-5 g-4">
        {articles.map((article) => (
          <Col xs={12} sm={6} md={4} lg={3} key={article.id}>
            <Card className="custom-card">
              {article.media &&
              article.mediaType &&
              article.mediaType.startsWith("video/") ? (
                <video
                  controls
                  src={article.media}
                  className="custom-media-overlay"
                />
              ) : (
                <Card.Img
                  variant="top"
                  src={article.media}
                  alt={article.title}
                  className="custom-media-overlay"
                />
              )}

              <Card.Body className="custom-overlay-content text-white">
                <Card.Title>{article.title}</Card.Title>
                <Card.Text>{article.description}</Card.Text>
              </Card.Body>
            </Card>
            <Button
              variant="primary"
              onClick={() => {
                setFormState(article);

                // Faire défiler la page vers le formulaire
                if (formRef.current) {
                  formRef.current.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <FaEdit />
            </Button>

            <Button
              variant="danger"
              onClick={() => handleDelete(article.id, article.media)}
              className="ml-2"
            >
              <FaTrash />
            </Button>
          </Col>
        ))}
      </Row>
    </Col>
  );
}

export default ArticlesManager;
