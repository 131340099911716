import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Alert } from "react-bootstrap";
import emailjs from "emailjs-com";
import "../styles/Contact.css";

function Contact() {
  const [status, setStatus] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const templateParams = {
      name: formData.get("name"),
      email: formData.get("email"),
      message: formData.get("message"),
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          setStatus({
            type: "success",
            message: "Votre message a été envoyé avec succès !",
          });
          setTimeout(() => setStatus(null), 5000);
        },
        (error) => {
          console.error("Erreur:", error);
          setStatus({
            type: "error",
            message: "Une erreur est survenue. Veuillez réessayer plus tard.",
          });
          setTimeout(() => setStatus(null), 5000);
        }
      );

    e.target.reset();
  };

  return (
    <Container fluid className="my-5">
      <Row className="d-flex align-items-center">
        <h1 className="mt-5 text-center">Contact</h1>
        <Col md={6} className="mt-5">
          <img
            src="assets/images/contact.svg"
            alt="Formulaire de contact"
            className="img-fluid"
          />
        </Col>
        <Col md={6} className="mt-5">
          <h3>
            Vous avez un projet ou des questions sur mes services ? Je suis à
            votre disposition !
          </h3>
          <p>
            Remplissez simplement le formulaire ci-dessous, et je vous répondrai
            dans les plus brefs délais.
          </p>

          <Form onSubmit={sendEmail}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className="fw-bold">
                Nom*, Prénom*, Raison sociale
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Votre Nom, Prénom, Raison Sociale"
                name="name"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <Form.Label className="fw-bold">Email*</Form.Label>
              <Form.Control
                type="email"
                placeholder="Votre email"
                name="email"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="message">
              <Form.Label className="fw-bold">Message*</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Votre message"
                name="message"
                required
              />
            </Form.Group>

            {status && (
              <Alert
                variant={status.type === "success" ? "success" : "danger"}
                style={{ width: "max-content" }}
              >
                {status.message}
              </Alert>
            )}

            <Button
              variant="warning"
              type="submit"
              className="fs-4 btn-hover-zoom"
            >
              Envoyer
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
