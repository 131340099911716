import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalytics = ({ trackingId }) => {
  const location = useLocation();

  useEffect(() => {
    // Charger le script Google Analytics
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;
    document.body.appendChild(script);

    // Configuration de Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', trackingId, {
      page_path: location.pathname, // Enregistrer chaque changement de page
    });
  }, [location, trackingId]); // La fonction est rappelée à chaque changement de route

  return null; // Pas besoin de rendre quoi que ce soit
};

export default GoogleAnalytics;
