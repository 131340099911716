import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Configuration Firebase
const firebaseConfig = {
    apiKey: "AIzaSyD346AaYCznU_Rpr8xWsVWt4VmrY8qzUR4",
    authDomain: "solutionweb-448ce.firebaseapp.com",
    projectId: "solutionweb-448ce",
    storageBucket: "solutionweb-448ce.appspot.com",
    messagingSenderId: "529177730539",
    appId: "1:529177730539:web:585294a217e2af5c868dae",
    measurementId: "G-MQYK1N2BR9"
};

// Initialisation de Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
