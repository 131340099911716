import React, { useState } from "react";
import { auth } from "./Firebase";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Form, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Login({ closeModal }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Veuillez entrer un email valide.");
      return;
    }
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Utilisateur connecté : ", userCredential.user);
        closeModal();
        navigate("/content-manager"); 
        // Ferme le modal après la connexion réussie
      })
      .catch((error) => {
        setError("Email ou mot de passe incorrect.");
      });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleForgotPassword = () => {
    if (!email) {
      setError("Veuillez entrer votre email pour réinitialiser votre mot de passe.");
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage("Un email de réinitialisation a été envoyé à votre adresse.");
      })
      .catch((error) => {
        setError("Erreur lors de la réinitialisation du mot de passe.");
      });
  };

  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}
      {message && <Alert variant="success">{message}</Alert>}
      <Form onSubmit={handleLogin}>
        <Form.Group className="mb-3 fw-bold">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 fw-bold">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Button type="submit" className="w-100">
          Connexion
        </Button>
      </Form>
      <Button onClick={handleForgotPassword} variant="link" className="mt-2">
        Mot de passe oublié ?
      </Button>
    </div>
  );
}

export default Login;
