import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { db } from "../components/Firebase"; // Assurez-vous que ce chemin est correct
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import "../styles/Galerie.css";

function Galerie() {
  // État pour stocker le titre et la description existants
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  // État pour stocker les articles
  const [articles, setArticles] = useState([]);

  // Fonction pour récupérer le titre et la description d'un document Firestore spécifique
  const fetchContent = async () => {
    const docRef = doc(db, "contenu", "IMxamWzEbadufG7RtzuQ"); // ID de ton document
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      setTitle(data.titre);
      setDescription(data.description);
    } else {
      console.log("Aucun document trouvé !");
    }
  };

  // Fonction pour récupérer les articles depuis Firestore
  const fetchArticles = async () => {
    try {
      const articlesCollectionRef = collection(db, "articles");
      const articlesQuery = query(
        articlesCollectionRef,
        orderBy("createdAt", "asc")
      ); // Trier par createdAt
      const articlesSnapshot = await getDocs(articlesQuery);
      const articlesList = articlesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArticles(articlesList);
    } catch (error) {
      console.error("Erreur lors de la récupération des articles:", error);
    }
  };

  // Récupération des données au chargement de la page
  useEffect(() => {
    fetchContent();
    fetchArticles();
  }, []);

  return (
    <Container fluid className="my-5">
      {/* Titre et Description venant de Firestore */}
      <Row>
        <Col md={6} className="mt-5 text-center">
          <h1>{title}</h1>
        </Col>
        <Col md={4} className="mt-5">
          <p>{description}</p>
        </Col>
      </Row>

      <Row className="mt-5 g-4">
        {articles.map((article) => (
          <Col xs={12} sm={6} md={4} lg={3} key={article.id}>
            <Card className="galerie-card">
              {article.media &&
              article.mediaType &&
              article.mediaType.startsWith("video/") ? (
                <video 
                controls 
                src={article.media} 
                className="image-overlay" />
              ) : (
                <Card.Img
                  variant="top"
                  src={article.media}
                  alt={article.title}
                  className="image-overlay"
                />
              )}
              <Card.Body className="overlay-content text-white">
                <Card.Title>{article.title}</Card.Title>
                <Card.Text>{article.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Galerie;
